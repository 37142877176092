<script setup lang="ts">
import type { PriceProps } from './UIPrice.props'

defineProps<PriceProps>()

const { currentCurrency } = useCurrency()
const { formatPriceByLocale } = useFormatPrice()
</script>

<template>
  <div
    v-if="discountedPrice && originalPrice !== discountedPrice"
    class="text-light-6 flex min-h-5 w-full flex-1 flex-wrap justify-center gap-x-2"
  >
    <span
      class="text-primitives-grey-200 lg:group-hover:text-text-tertiary line-through"
    >
      {{ formatPriceByLocale(currentCurrency, originalPrice) }}
    </span>
    <span v-if="discountedPrice">{{
      formatPriceByLocale(currentCurrency, discountedPrice)
    }}</span>
    <span v-if="discount" class="text-medium-6">{{ discount }}</span>
  </div>
  <div v-else-if="originalPrice" class="text-light-6">
    {{ formatPriceByLocale(currentCurrency, originalPrice) }}
  </div>
</template>
