<script lang="ts" setup>
import type { ProductTileImagesProps } from './UIProductTileImages.props'
const props = withDefaults(defineProps<ProductTileImagesProps>(), {
  hoverImage: false,
  fetchPriorityImage: false,
  disableSwipeMobile: false,
})
defineEmits<{
  (e: 'prev'): void
  (e: 'next'): void
}>()

const onlyImages = computed(() => {
  const images: Array<any> = []
  props.images.forEach(item => {
    if (item.type === 'TResponsiveImage' && !item.URL?.includes('video')) {
      images.push(item)
    }
  })

  return images
})
</script>
<template>
  <div class="aspect-pdp-image group relative">
    <div class="relative">
      <NuxtPicture
        v-for="(image, index) in onlyImages"
        :key="image.URL"
        provider="cloudinary"
        :src="image.URL"
        :alt="createSlug(product.name, product.defaultColor, '', product.brand)"
        :placeholder="[500, 600, 50, 10]"
        sizes="lg:30vw sm:100vw"
        width="630"
        height="800"
        quality="auto:good"
        class="block h-auto w-full opacity-0 transition-opacity delay-75 duration-150 ease-in-out"
        :class="{
          'opacity-100 !delay-0':
            activeImage &&
            activeImage.type === 'TResponsiveImage' &&
            image.URL === activeImage.URL,
          'absolute top-0 z-[1]': index > 0,
          'hidden lg:block': disableSwipeMobile && index > 0,
        }"
        fit="fill"
        :loading="!fetchPriorityImage ? 'lazy' : undefined"
        :img-attrs="{
          fetchpriority: fetchPriorityImage ? 'high' : undefined,
          class: 'h-full w-full object-contain',
        }"
      />
    </div>
    <Transition
      enter-active-class="ease-out duration-150"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-75"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <video
        v-if="
          (activeImage && activeImage.type === 'TResponsiveVideo') ||
          activeImage?.URL?.includes('video')
        "
        :muted="true"
        :autoplay="true"
        :loop="true"
        controlsList="nofullscreen nodownload"
        webkit-playsinline
        playsinline
        :poster="
          activeImage && activeImage.type === 'TResponsiveVideo' && !hoverImage
            ? posterVideo(activeImage.URL)
            : undefined
        "
        width="630"
        height="800"
        :data-image-index="activeImage?.URL"
        class="absolute inset-0 z-[1] h-full w-full cursor-pointer object-cover"
      >
        <div class="absolute inset-0 z-[1]">
          {{
            activeImage && activeImage.type === 'TResponsiveVideo'
              ? posterVideo(activeImage.URL)
              : undefined
          }}
        </div>
        <source
          :src="`${optimizeVideo(activeImage?.URL, 'best', undefined, 'webm')}#t=0.001`"
          type="video/webm"
        />
        <source
          :src="`${optimizeVideo(activeImage?.URL, 'best')}#t=0.001`"
          type="video/mp4"
        />
      </video>
    </Transition>
    <span
      class="absolute left-2 top-1/2 z-[2] hidden p-3 opacity-0 transition-opacity duration-300 lg:inline-block"
      :class="{
        'opacity-100':
          hoverImage && activeImage && activeImage.URL !== images[0].URL,
      }"
      @click.prevent="$emit('prev')"
    >
      <DefaultIconsChevronLeft class="h-6 w-6" />
    </span>
    <span
      class="absolute right-2 top-1/2 z-[2] hidden p-3 opacity-0 transition-opacity duration-300 lg:inline-block"
      :class="{
        'opacity-100':
          hoverImage &&
          activeImage &&
          activeImage.URL !== images[images.length - 1].URL,
      }"
      @click.prevent="$emit('next')"
    >
      <DefaultIconsChevronRight class="h-6 w-6" />
    </span>
    <div
      class="relative mt-2 block w-full lg:hidden"
      :class="{
        '!hidden': disableSwipeMobile,
      }"
    >
      <div class="flex h-full w-full items-center justify-center">
        <template v-if="images.length > 1">
          <span
            v-for="image in images"
            :key="image.URL"
            class="md:group-hover:bg-background-primary bg-neutral-black mx-1 h-1 w-1 rounded-full border-solid opacity-10"
            :class="{
              '!opacity-100': activeImage?.URL === image.URL,
            }"
          />
        </template>
        <span v-else class="h-1" />
      </div>
    </div>
  </div>
</template>
